import request from '@/api/maintenance-service'

/**
 * 
 * @param {String} deviceId deviceId
 * @returns {Promise}
 */
export function searchUser(deviceId) {
  return request({
    url: 'maintenance/user',
    method: "post",
    data: {
      deviceId
    }
  })
}

/**
 * 
 * @param {String} userId userId
 * @returns {Promise}
 */
export function deleteUser(userId, deviceId) {
  return request({
    url: 'maintenance/user',
    method: "delete",
    data: {
      userId,
      deviceId,
    }
  })
}