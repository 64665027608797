<template>
  <div class="container">
    <el-card class="form-container">
      <el-form
        @submit.native.prevent="submitForm"
        :model="data"
        :rules="rules"
        ref="data"
        label-position="center"
        style="width: 300px"
      >
        <h3 class="title">{{ $t('maintenance.device.search-title') }}</h3>
        <div class="search">
          <el-form-item prop="deviceId">
            <el-input
              type="text"
              v-model="data.deviceId"
              auto-complete="on"
              :placeholder="$t('maintenance.device.id-placeholder')"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: left">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click.native.prevent="submitForm"
              :loading="submitting"
            ></el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-card v-if="user" class="user-card">
        <div class>
          <span>{{user.username}}</span>
          <el-button
            class="delete-button"
            type="text"
            icon="el-icon-delete"
            @click.prevent="handleDeleteUser(user.userId)"
            :loading="deleting || submitting"
          ></el-button>
        </div>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import { searchUser, deleteUser } from "@/api/maintenance";

export default {
  name: "Maintenance",
  data: function() {
    return {
      submitting: false,
      deleting: false,
      data: {
        deviceId: ""
      },
      user: null,

      rules: {
        deviceId: [
          {
            required: true,
            message: this.$t("maintenance.device.id-required"),
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value.trim()) {
                callback(new Error(this.$t("maintenance.device.id-required")));
              }
              callback();
            }
          }
        ]
      }
    };
  },

  methods: {
    submitForm: function(ev) {
      this.$refs.data.validate(valid => {
        if (!valid) {
          return;
        }
        this.user = null;
        this.submitting = true;
        searchUser(this.data.deviceId.trim())
          .then(({ status, data }) => {
            if (status === "success" && data) {
              this.user = data;
              this.$message.success(this.$t("maintenance.user.search-success"));
            } else {
              this.user = null;
              this.$message.error(this.$t("maintenance.user.search-not-found"));
            }
            this.submitting = false;
          })
          .catch(error => {
            console.log("maintenance.user.search-error", error);
            this.$message.error(this.$t("maintenance.user.search-error"));
            this.user = null;
            this.submitting = false;
          });
      });
    },
    handleDeleteUser(userId) {
      this.deleting = true;
      deleteUser(userId, this.data.deviceId)
        .then(({ status, data }) => {
          if (status === "success" && data && data.userId === userId) {
            this.$message.success(this.$t("maintenance.user.delete-success"));
            this.user = null;
            this.data.deviceId = "";
          }
          this.deleting = false;
        })
        .catch(error => {
          console.log("maintenance.user.delete-error", error);
          this.$message.error(this.$t("maintenance.user.delete-error"));
          this.deleting = false;
        });
    }
  }
};
</script>


<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}

.form-container-old {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  /*margin: 80px auto;*/
  margin: 20px;
  width: 500px;
  padding: 35px 35px 15px 35px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-card {
  width: 100%;
}
.delete-button {
  float: right;
  padding: 3px 0;
}
</style>